<template>
  <fragment>
    <base-alert v-if="error">{{ error.message }}</base-alert>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <v-form @submit.prevent="handleSubmit(onSubmit)">
        <v-row justify="center">
          <v-col cols="12" md="10" xl="8">
            <h1 class="headline mb-5" v-if="!activity">Angebot kostenlos publizieren</h1>
            <h1 class="headline mb-5" v-else>Angebot bearbeiten «{{ activity.title.DE }}»</h1>
            <v-stepper alt-labels class="activity-form-stepper" v-model="currentStep">
              <v-stepper-header>
                <template v-for="(step, i) in steps">
                  <v-divider :key="`divider-${step.key}`" v-if="i > 0" />
                  <v-stepper-step :key="`step-${step.key}`" :step="i + 1" :complete="currentStep > i + 1" :editable="currentStep > i + 1" edit-icon="$complete">{{ step.label }}</v-stepper-step>
                </template>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content v-for="(step, i) in steps" :key="`content-${step.key}`" :step="i + 1">
                  <ValidationObserver v-if="currentStep === i + 1">
                    <template v-if="step.key === 'offer'">
                      <ActivityType />
                      <Title />
                      <Url />
                    </template>
                    <template v-if="step.key === 'category'">
                      <Category />
                      <Additional />
                    </template>
                    <template v-if="step.key === 'participant'">
                      <Participant />
                    </template>
                    <template v-if="step.key === 'date'">
                      <Date />
                    </template>
                    <template v-if="step.key === 'images'">
                      <Images />
                    </template>
                    <Action @back="handleBack" :is-first="i === 0" :submit="i >= steps.length - 1 ? 'Speichern' : 'Weiter'" />
                  </ValidationObserver>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { ValidationObserver } from 'vee-validate';
import router from '@/router';
import { scrollToTop } from '@/helpers/scrollToTop';

const { mapFields } = createHelpers({
  getterType: 'activity/field',
  mutationType: 'activity/updateField',
});

export default {
  components: {
    ValidationObserver,
    Action: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/modules/Action.vue'),
    ActivityType: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/modules/ActivityType.vue'),
    Title: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/modules/Title.vue'),
    Url: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/modules/Url.vue'),
    Category: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/modules/Category.vue'),
    Additional: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/modules/Additional.vue'),
    Participant: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/modules/Participant.vue'),
    Date: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/modules/Date.vue'),
    Images: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/modules/Images.vue'),
  },

  data: () => ({
    currentStep: 1,
  }),

  computed: {
    ...mapGetters({
      activity: 'activity/get',
      loading: 'loading/get',
      error: 'error/get',
    }),
    // eslint-disable-next-line max-len
    ...mapFields(['status', 'activityType', 'locationType', 'title', 'surrounding', 'ages', 'parents', 'buggyFriendly', 'description', 'costFree', 'costDescription', 'ticketUrl', 'category', 'subCategory', 'venue', 'venueName', 'venueAddress', 'venueZip', 'venueCity', 'dates', 'highlight', 'urls', 'images']),
    stepsFull() {
      return [
        {
          key: 'offer',
          label: 'Angebot',
        }, {
          key: 'category',
          label: 'Kategorisierung',
        }, {
          key: 'participant',
          label: 'Teilnehmer',
        }, {
          key: 'date',
          label: this.dates && this.dates.type === 'OPENINGTIMES' ? 'Öffnungszeiten' : 'Termine',
        }, {
          key: 'images',
          label: 'Bilder',
        },
      ];
    },
    steps() {
      if (this.locationType === 'ONLINE' && (this.activityType === 'PLACE' || this.activityType === 'MANUAL')) {
        return this.stepsFull.filter((s) => s.key !== 'participant');
      }

      if (this.locationType === 'ONLINE' && this.activityType === 'SHOP') {
        return this.stepsFull.filter((s) => s.key !== 'participant' && s.key !== 'date');
      }

      return this.stepsFull;
    },
  },

  watch: {
    currentStep: {
      handler(value) {
        this.currentStep = Number.parseInt(value, 10);
      },
      immediate: true,
    },
    activity: {
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.currentStep = 1;
        }
      },
    },
  },

  methods: {
    onSubmit() {
      if (this.$data.currentStep <= this.steps.length - 1) {
        scrollToTop();
        this.$data.currentStep += 1;
      } else {
        const ACTIVITY_DATA = {
          status: this.status,
          activityType: this.activityType,
          locationType: this.locationType,
          title: this.title,
          surrounding: this.surrounding,
          ages: this.ages,
          parents: this.parents,
          buggyFriendly: this.buggyFriendly,
          description: this.description,
          costFree: this.costFree,
          costDescription: this.costDescription,
          ticketUrl: this.ticketUrl,
          category: this.category,
          subCategory: this.subCategory,
          venue: this.venue,
          venueName: this.venueName,
          venueAddress: this.venueAddress,
          venueZip: this.venueZip,
          venueCity: this.venueCity,
          dates: this.dates,
          highlight: this.highlight,
          urls: this.urls,
          images: this.images,
        };

        if (this.activity) {
          this.$store.dispatch('activity/update', ACTIVITY_DATA).then(() => {
            router.push(this.$url('/'));
          });
        } else {
          this.$store.dispatch('activity/create', ACTIVITY_DATA).then(() => {
            router.push(this.$url('/'));
          });
        }
      }
    },
    handleBack() {
      scrollToTop();
      this.$data.currentStep -= 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-form-stepper {
  &,
  &.theme--light {
    box-shadow: none;
    background: none;
  }

  .v-stepper__header {
    box-shadow: none;
    margin-left: -24px;
    margin-right: -24px;
  }

  // .v-stepper__step.v-stepper__step--active {
  //   &::v-deep .v-stepper__step__step {
  //     background-color: #fff !important;
  //     border: 1px solid;
  //     color: #64a175;
  //   }
  // }

  .v-stepper__items {
    margin-left: -24px;
    margin-right: -24px;
  }
}
</style>
