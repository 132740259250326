<template>
  <fragment>
    <base-title v-model="search">Mein Angebot</base-title>
    <v-card>
      <v-tooltip bottom open-delay="500" v-if="$can('create', 'Activity')">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark small absolute top right fab :to="$url('/activity/create')" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Gratis inserieren</span>
      </v-tooltip>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
        :loading="loading"
        :footer-props="{ itemsPerPageOptions: [10, 20, -1] }"
        :items-per-page.sync="itemsPerPage"
        :item-class="activityDataTableClass"
        item-key="id"
        virtual-rows
        sort-by="title.DE"
      >
        <template v-slot:no-data>
          <v-card flat class="my-5">
            <v-card-title class="headline d-inline-flex">Keine Veranstaltungen vorhanden</v-card-title>
            <v-card-text>Erstelle deine erste Veranstaltung</v-card-text>
            <v-card-actions class="d-inline-flex" v-if="$can('create', 'Activity')">
              <v-btn color="primary" :to="$url('/activity/create')">Hinzufügen</v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <template v-slot:no-results>
          <v-card flat class="my-5">
            <v-card-title class="headline d-inline-flex">Keine Veranstaltungen gefunden</v-card-title>
            <v-card-text>Mit dem Begriff "{{ search }}" wurden keine Veranstaltungen gefunden.</v-card-text>
            <v-card-actions class="d-inline-flex" v-if="$can('create', 'Activity')">
              <v-btn color="primary" :to="$url('/activity/create')">Hinzufügen</v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <template v-slot:item.category="{ item }">
          <span v-if="item.category && item.category.name">{{ item.category.name.de }}</span>
        </template>
        <template v-slot:item.activityType="{ item }">{{ getActivityTypesText(item.activityType) }} ({{ getLocationTypesText(item.locationType) }})</template>
        <template v-slot:item.createdAt="{ item }">
          <v-tooltip bottom open-delay="500">
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ item.createdAt | formatDate }}</span>
            </template>
            <span>{{ item.createdAt | formatDateTime }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.dates.last="{ item }">
          <v-tooltip bottom open-delay="500" v-if="item.dates.last">
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ item.dates.last | formatDate }}</span>
            </template>
            <span>{{ item.dates.last | formatDateTime }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.images="{ item }">
          <v-tooltip bottom open-delay="500" v-if="$vuetify.breakpoint.smAndUp && item.images.length > 0">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-file-image</v-icon>
            </template>
            <span>Hat ein oder mehrere Bilder</span>
          </v-tooltip>
          <div v-if="$vuetify.breakpoint.xs && item.images.length > 0" class="d-flex align-center">
            Hat ein oder mehrere Bilder
            <v-icon right>mdi-file-image</v-icon>
          </div>
        </template>
        <template v-slot:item.action="{ item }">
          <div :class="$vuetify.breakpoint.xs ? 'flex flex-wrap pb-2' : 'text-no-wrap'">
            <base-mobile-button
              :icon="item.status === 'PUBLISHED' ? 'mdi-eye' : 'mdi-eye-off'"
              :tooltip="item.status === 'PUBLISHED' ? 'Diese Veranstaltung nicht mehr veröffentlichen anzeigen' : 'Diese Veranstaltung veröffentlichen'"
              v-if="$can('publish', 'Activity') && item.canBePublished"
              @click="toggleStatus(item)"
            >
              <span v-if="item.status === 'PUBLISHED'">Verbergen</span>
              <span v-else>Veröffentlichen</span>
            </base-mobile-button>
            <base-mobile-button
              :icon="item.highlight ? 'mdi-star' : 'mdi-star-outline'"
              :tooltip="item.highlight ? 'Von den wow-wow Tipps entfernen' : 'Zu Tipps hinzufügen'"
              v-if="$can('highlight', 'Activity')"
              @click="toggleHighlight(item)"
            >
              <span v-if="item.highlight">Von Tipps entfernen</span>
              <span v-else>Zu Tipps hinzufügen</span>
            </base-mobile-button>
            <base-mobile-button icon="mdi-pencil" tooltip="Bearbeiten" v-if="$can('update', 'Activity')" :to="$url(`/activity/${item.id}/edit`)">
              <span>Bearbeiten</span>
            </base-mobile-button>
            <base-mobile-button icon="mdi-delete" tooltip="Löschen" v-if="$can('delete', 'Activity')" @click="deleteActivity(item)">
              <span>Löschen</span>
            </base-mobile-button>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </fragment>
</template>

<script>
import dayjs from 'dayjs';
import { getTextByValue as getActivityTextByValue } from '@/helpers/activityTypes';
import { getTextByValue as getLocationTextByValue } from '@/helpers/locationTypes';

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    search: '',
    headers: [
      { text: 'Veranstaltungstitel', value: 'title.DE' },
      { text: 'Kategorie', value: 'category' },
      { text: 'Veranstaltungstyp', value: 'activityType' },
      { text: 'Erstellt am', value: 'createdAt' },
      { text: 'Letzte Veranstaltung', value: 'dates.last' },
      { value: 'images', sortable: false },
      { value: 'action', align: 'end', sortable: false },
    ],
  }),

  computed: {
    itemsPerPage: {
      get() {
        return Number.parseInt(localStorage.getItem('table-activity-items'), 10) || 10;
      },
      set(value) {
        localStorage.setItem('table-activity-items', value);
      },
    },
  },

  filters: {
    formatDate(value) {
      if (value) {
        return dayjs(value.toDate()).format('DD.MM.YYYY');
      }
      return null;
    },
    formatDateTime(value) {
      if (value) {
        return dayjs(value.toDate()).format('DD.MM.YYYY HH:mm');
      }
      return null;
    },
  },

  methods: {
    activityDataTableClass(activity) {
      if (activity.status !== 'PUBLISHED') {
        return 'grey--text';
      }
      return null;
    },
    deleteActivity(activity) {
      this.$store.commit('activity/dialog/delete', {
        id: activity.id,
        path: activity.path,
        title: activity.title,
      });
    },
    toggleStatus(activity) {
      const status = activity.status !== 'PUBLISHED' ? 'PUBLISHED' : 'REVIEW';
      this.$store.dispatch('activity/status', { id: activity.id, status });
    },
    toggleHighlight(activity) {
      this.$store.dispatch('activity/highlight', { id: activity.id, highlight: !activity.highlight });
    },
    getActivityTypesText(value) {
      return getActivityTextByValue(value);
    },
    getLocationTypesText(value) {
      return getLocationTextByValue(value);
    },
  },
};
</script>
