<template>
  <fragment>
    <!-- <div class="d-flex flex-no-wrap justify-end mb-3">
      <v-btn-toggle v-model="layout" mandatory>
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{ on }">
            <v-btn icon small value="table" v-on="on">
              <v-icon small>mdi-table-large</v-icon>
            </v-btn>
          </template>
          <span>Tabellen-Ansicht</span>
        </v-tooltip>
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{ on }">
            <v-btn icon small value="list" v-on="on">
              <v-icon small>mdi-view-grid</v-icon>
            </v-btn>
          </template>
          <span>Kachel-Ansicht</span>
        </v-tooltip>
      </v-btn-toggle>
    </div>
    <div>
      <Table :data="data" :loading="loading" v-if="layout === 'table'" />
      <List :data="data" :loading="loading" v-else />
    </div>-->
    <Table :data="data" :loading="loading" />
    <Delete />
  </fragment>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    Table: () => import(/* webpackChunkName: "activity" */ '@/components/scoped/activity/Table.vue'),
    // List: () => import(/* webpackChunkName: "activity" */ '@/components/scoped/activity/List.vue'),
    Delete: () => import(/* webpackChunkName: "activity" */ '@/components/scoped/activity/Delete.vue'),
  },

  data: () => ({
    layout: 'table',
  }),
};
</script>
