var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('base-title',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_vm._v("Mein Angebot")]),_c('v-card',[(_vm.$can('create', 'Activity'))?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":"","small":"","absolute":"","top":"","right":"","fab":"","to":_vm.$url('/activity/create')}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,1933229559)},[_c('span',[_vm._v("Gratis inserieren")])]):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [10, 20, -1] },"items-per-page":_vm.itemsPerPage,"item-class":_vm.activityDataTableClass,"item-key":"id","virtual-rows":"","sort-by":"title.DE"},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-card',{staticClass:"my-5",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"headline d-inline-flex"},[_vm._v("Keine Veranstaltungen vorhanden")]),_c('v-card-text',[_vm._v("Erstelle deine erste Veranstaltung")]),(_vm.$can('create', 'Activity'))?_c('v-card-actions',{staticClass:"d-inline-flex"},[_c('v-btn',{attrs:{"color":"primary","to":_vm.$url('/activity/create')}},[_vm._v("Hinzufügen")])],1):_vm._e()],1)]},proxy:true},{key:"no-results",fn:function(){return [_c('v-card',{staticClass:"my-5",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"headline d-inline-flex"},[_vm._v("Keine Veranstaltungen gefunden")]),_c('v-card-text',[_vm._v("Mit dem Begriff \""+_vm._s(_vm.search)+"\" wurden keine Veranstaltungen gefunden.")]),(_vm.$can('create', 'Activity'))?_c('v-card-actions',{staticClass:"d-inline-flex"},[_c('v-btn',{attrs:{"color":"primary","to":_vm.$url('/activity/create')}},[_vm._v("Hinzufügen")])],1):_vm._e()],1)]},proxy:true},{key:"item.category",fn:function(ref){
var item = ref.item;
return [(item.category && item.category.name)?_c('span',[_vm._v(_vm._s(item.category.name.de))]):_vm._e()]}},{key:"item.activityType",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getActivityTypesText(item.activityType))+" ("+_vm._s(_vm.getLocationTypesText(item.locationType))+")")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("formatDate")(item.createdAt)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.createdAt)))])])]}},{key:"item.dates.last",fn:function(ref){
var item = ref.item;
return [(item.dates.last)?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("formatDate")(item.dates.last)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.dates.last)))])]):_vm._e()]}},{key:"item.images",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp && item.images.length > 0)?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-file-image")])]}}],null,true)},[_c('span',[_vm._v("Hat ein oder mehrere Bilder")])]):_vm._e(),(_vm.$vuetify.breakpoint.xs && item.images.length > 0)?_c('div',{staticClass:"d-flex align-center"},[_vm._v(" Hat ein oder mehrere Bilder "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-file-image")])],1):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.$vuetify.breakpoint.xs ? 'flex flex-wrap pb-2' : 'text-no-wrap'},[(_vm.$can('publish', 'Activity') && item.canBePublished)?_c('base-mobile-button',{attrs:{"icon":item.status === 'PUBLISHED' ? 'mdi-eye' : 'mdi-eye-off',"tooltip":item.status === 'PUBLISHED' ? 'Diese Veranstaltung nicht mehr veröffentlichen anzeigen' : 'Diese Veranstaltung veröffentlichen'},on:{"click":function($event){return _vm.toggleStatus(item)}}},[(item.status === 'PUBLISHED')?_c('span',[_vm._v("Verbergen")]):_c('span',[_vm._v("Veröffentlichen")])]):_vm._e(),(_vm.$can('highlight', 'Activity'))?_c('base-mobile-button',{attrs:{"icon":item.highlight ? 'mdi-star' : 'mdi-star-outline',"tooltip":item.highlight ? 'Von den wow-wow Tipps entfernen' : 'Zu Tipps hinzufügen'},on:{"click":function($event){return _vm.toggleHighlight(item)}}},[(item.highlight)?_c('span',[_vm._v("Von Tipps entfernen")]):_c('span',[_vm._v("Zu Tipps hinzufügen")])]):_vm._e(),(_vm.$can('update', 'Activity'))?_c('base-mobile-button',{attrs:{"icon":"mdi-pencil","tooltip":"Bearbeiten","to":_vm.$url(("/activity/" + (item.id) + "/edit"))}},[_c('span',[_vm._v("Bearbeiten")])]):_vm._e(),(_vm.$can('delete', 'Activity'))?_c('base-mobile-button',{attrs:{"icon":"mdi-delete","tooltip":"Löschen"},on:{"click":function($event){return _vm.deleteActivity(item)}}},[_c('span',[_vm._v("Löschen")])]):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }